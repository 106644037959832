import clsx from 'clsx'
import { rem } from '~/utils'

interface Props {
  className?: string
  user: { emailMd5: string | null }
  size?: number
  autoDPI?: boolean
}

function Gravatar({ className, user, size = 32, autoDPI = true }: Props) {
  const d = user.emailMd5 ? 'retro' : 'mp'
  const formatUrl = (s: number) => `https://www.gravatar.com/avatar/${user.emailMd5}?s=${s}&d=${d}`

  return (
    <img
      src={formatUrl(size)}
      srcSet={autoDPI ? `${formatUrl(size * 2)} 2x, ${formatUrl(size * 3)} 3x` : undefined}
      style={{ width: rem(size), height: rem(size) }}
      alt=""
      className={clsx('rounded-full', className)}
    />
  )
}

export default Gravatar
